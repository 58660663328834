<template>
  <div class="section section-white">
    <el-form ref="form" :model="form" label-width="150px" label-position="right" class="demo-ruleForm">
      <template v-if="!isEditor || (isEditor && !form.item_main_cat_id)">
        <el-card shadow="never" header="选择主类目" v-loading="mainCateLoader">
          <el-cascader
            :options="mainCategory"
            v-model="selectedMainCategory"
            @change="handleCategoryChange"
            style="width: 360px;">
          </el-cascader>
        </el-card>
      </template>
      <div class="content-padded view-flex view-flex-middle" v-loading="loader" v-else>
        <div>主类目：</div>
        <el-breadcrumb separator-class="el-icon-arrow-right" class="inline">
          <el-breadcrumb-item v-for="(item, index) in categoryNames" :key="index">{{ item }}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <template v-if="(!isEditor && selectedMainCategory.length > 0) || isEditor">


        <el-row>
          <el-col :span="18" style="padding-right:20px">
            <el-card shadow="never" v-loading="loader">
              <div slot="header" class="clearfix">
                <span>基础信息</span>
                <el-button style="float: right; padding: 3px 0" type="text" @click="panelCollapse('base')"><i
                    class="iconfont" :class="panel.base ? 'icon-window-minimize1' : 'icon-plus'"></i></el-button>
              </div>

              <transition name="el-zoom-in-top">
                <div class="form-collapse" v-show="panel.base">
                  <el-row :gutter="20">
                    <el-col :xs="24" :sm="12" :md="12">
                      <el-form-item label="*商品标题">
                        <el-input v-model="form.item_name" :maxlength="100" placeholder=""></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12">
                      <el-form-item label="副标题">
                        <el-input v-model="form.brief" :maxlength="30" placeholder=""></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12">
                      <el-form-item label="切换规格描述">
                        <el-input v-model="form.item_en_name" :maxlength="100" placeholder=""></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12">
                      <el-form-item label="分类页标签">
                        <el-input v-model="form.item_tag_name" :maxlength="100" placeholder=""></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" v-show="hidden">
                      <el-form-item label="*运费模板">
                        <el-select v-model="form.templates_id" placeholder="请选择">
                          <el-option v-for="item in templatesList" :key="item.template_id" :label="item.name"
                            :value="item.template_id"></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12">
                      <el-form-item label="*品牌">
                        <el-select v-model="form.brand_id" remote filterable :remote-method="getBrandList" clearable
                          placeholder="请选择">
                          <el-option v-for="item in brandList" :key="item.attribute_id" :label="item.attribute_name"
                            :value="item.attribute_id">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12">
                      <el-form-item label="计量单位">
                        <el-input v-model="form.item_unit" :maxlength="60" placeholder=""></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12">
                      <el-form-item label="排序编号">
                        <el-input v-model="form.sort" placeholder=""></el-input>
                      </el-form-item>
                    </el-col>
                    <!-- <el-col :xs="24" :sm="12" :md="8">
                  <el-form-item label="产地">
                    <el-cascader
                      placeholder="选择地区"
                      :options="regions"
                      v-model='select_regions_value'
                      @change="regionChange">
                    </el-cascader>
                  </el-form-item>
                </el-col> -->
                    <!-- <el-col :xs="24" :sm="12" :md="8">
                  <el-form-item label="商品税率">
                    <el-input v-model="form.tax_rate" placeholder=""></el-input>
                  </el-form-item>
                </el-col> -->
                    <!-- <el-col :xs="24" :sm="12" :md="8">
                  <el-form-item label="是否开启分润">
                    <el-switch
                     v-model="form.is_profit"
                     active-color="#13ce66"
                     inactive-color="#ff4949" @change="profitStatusChange">
                    </el-switch>
                  </el-form-item>
                </el-col> -->

                    <el-col :xs="24" :sm="12" :md="12">
                      <el-form-item label="*商品分类">
                        <treeselect :options="categoryList" :show-count="true" :multiple="true"
                          :disable-branch-nodes="true" v-model="form.item_category">
                        </treeselect>
                      </el-form-item>
                    </el-col>

                    <el-col :xs="24" :sm="12" :md="12" v-if="form.type != 1" style="display: none">
                      <el-form-item label="*是否为预约商品">
                        <el-switch v-model="form.is_appointment" active-color="#13ce66" inactive-color="#ff4949"
                          @change="appointmentStatusChange">
                        </el-switch>
                      </el-form-item>
                      <el-form-item v-if="form.is_appointment == true" label="*请选择预约时间">
                        <el-date-picker v-model="form.appointment_time" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" @change="dateChange"></el-date-picker>
		                  </el-form-item>
                </el-col>

                <!-- <el-col :xs="24" :sm="12" :md="8" v-if="form.type != 1">
                  <el-form-item label="*是否为教育优惠商品">
                    <el-switch
                      v-model="form.is_edu"
                      active-color="#13ce66" @change="eduStatusChange">
                    </el-switch>
                  </el-form-item>
                </el-col> -->


                <!-- <el-col :xs="24" :sm="12" :md="8" >
                  <el-form-item label="*特殊商品类型">
                    <template>
                      <div >
                        <el-radio-group v-model="form.special_value" size="mini"  @change="teirStatusChange">
                          <el-radio-button label="normal">普通商品</el-radio-button>
                          <el-radio-button label="acplus">AC+</el-radio-button>
                          <el-radio-button label="iup">iUP</el-radio-button>
                        </el-radio-group>
                      </div>
                    </template>
                  </el-form-item>
                </el-col> -->
                  <!--                  <el-form-item label="*是否AC+商品">-->
<!--                    <el-switch-->
<!--                      v-model="form.is_acplus"-->
<!--                      active-color="#13ce66" @change="acStatusChange">-->
<!--                    </el-switch>-->
<!--                  </el-form-item>-->
                <!-- <el-col :xs="24" :sm="12" :md="8" >
                  <el-form-item label="*是否为以旧换新商品">
                    <el-switch
                      v-model="form.is_old"
                      active-color="#13ce66" @change="oldStatusChange">
                    </el-switch>
                  </el-form-item>
                </el-col> -->

                <!-- <el-col :xs="24" :sm="12" :md="8" >
                  <el-form-item label="*是否为仅自提商品">
                    <el-switch
                      v-model="form.is_ziti"
                      active-color="#13ce66" @change="zitiStatusChange">
                    </el-switch>
                  </el-form-item>
                </el-col> -->

                <el-col :xs="24" :sm="12" :md="8" >
                  <el-form-item label="是否可搭售">
                    <el-switch v-model="form.is_addcart_goods" active-color="#13ce66" @change="autoIsGoodsChange">
                    </el-switch>
                  </el-form-item>
                </el-col>

                <el-col :xs="24" :sm="12" :md="8" >
                  <el-form-item label="*企业微信是否可分享">
                    <el-switch
                      v-model="form.wecom_share"
                      active-color="#13ce66" @change="wecomShareStatusChange">
                    </el-switch>
                  </el-form-item>
                </el-col> -->
                <!-- <el-col :xs="24" :sm="12" :md="8">
                  <el-form-item label="*是否为企业购商品">
                    <el-switch
                      v-model="form.is_ec"
                      active-color="#13ce66" @change="ecBuyChange">
                    </el-switch>
                  </el-form-item>
                </el-col> -->
                <!-- <el-col :xs="24" :sm="12" :md="8">
                  <el-form-item label="*是否支持自动接单">
                    <el-switch
                      v-model="form.auto_receiving"
                      active-color="#13ce66" @change="autoReceivingChange">
                    </el-switch>
                  </el-form-item>
                </el-col> -->

                <el-col :xs="24">
                  <el-form-item label="*商品图">
                    <div class="pics-box">
                      <ul class="goodspic-wrap">
                        <draggable v-model="form.pics" :options="dragIssuesOptions" class="components-view">
                          <li v-for="(item, index) in form.pics" :key='index' class="goodspic" @mouseenter="picsEnter(index)" @mouseleave="picsLeave">
                            <img :src="wximageurl + item" />
                            <div class="goodspic-mask" :class="(picsCurrent == index) ? 'on' : ''">
                              <div class="iconfont icon-trash-alt" @click="removePicsImg(index)"></div>
                              <div class="iconfont icon-arrows-alt"></div>
                            </div>
                          </li>
                        </draggable>
                      </ul>
                      <div class="upload-box" @click="handlePicsChange">
                        <i class="iconfont icon-camera"></i>
                      </div>
                      <imgPicker :dialog-visible="picsDialog" :sc-status="isGetPics" :is-most="multiple"
                          @chooseImg="pickPics" @closeImgDialog="closePicsDialog"></imgPicker>
                    </div>
                  </el-form-item>
                </el-col>
                    <el-col :xs="24">
                      <el-form-item label="*首页商品封面图">
                        <div>
                          <div>
                            <div @click="handleHomeImgChange" class="upload-box">
                              <img v-if="form.home_pic" :src="wximageurl + form.home_pic" class="avatar" />
                              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </div>
                          </div>
                          <imgPicker :dialog-visible="homeimgDialog" :sc-status="isGetImage" @chooseImg="pickImg"
                            @closeImgDialog="closeHomeImgDialog"></imgPicker>
                          <div class="frm-tips">
                            <p>1. 最多可上传1个图片，文件格式为bmp、png、jpeg、jpg或gif，大小不超过2M（建议尺寸：500px * 500px）</p>
                          </div>
                        </div>
                      </el-form-item>
                    </el-col>
                    <el-col :xs="24">
                      <el-form-item label="上传视频">
                        <videoPicker :data="itemVideo" @change="pickVideo"></videoPicker>
                        <el-button v-if="itemVideo.media_id" @click="deleteVideo" type="text">删除</el-button>
                      </el-form-item>
                    </el-col>
                    <el-col :xs="24" v-show="form.videos">
                      <el-form-item label="视频自动播放">
                        <el-switch v-model="form.video_auto_play" active-text="自动播放" inactive-text="手动播放">
                        </el-switch>
                      </el-form-item>
                    </el-col>
                    <el-col :xs="24" v-show="form.videos">
                      <el-form-item label="视频封面">
                        <div>
                          <div>
                            <div @click="handleVideoImgChange" class="upload-box">
                              <img v-if="form.video_pic_url" :src="wximageurl + form.video_pic_url" class="avatar" />
                              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </div>
                          </div>
                          <imgPicker :dialog-visible="videoImgDialog" :sc-status="isGetImage" @chooseImg="pickVideoImg"
                            @closeImgDialog="closeVideoImgDialog"></imgPicker>
                          <div class="frm-tips">
                            <p>1. 最多可上传1个图片，文件格式为bmp、png、jpeg、jpg或gif，大小不超过2M（建议尺寸：500px * 500px）</p>
                          </div>
                        </div>
                      </el-form-item>
                    </el-col>
                    <!-- <el-col :xs="60" :sm="30" :md="60">
                  <el-form-item label="所属店铺"  >
                    <el-tag
                      :key="store"
                      v-for=" store in form.shustore"
                      effect="dark">
                      {{ store }}
                    </el-tag>
                  </el-form-item>
                </el-col> -->
                  </el-row>
                </div>
              </transition>
            </el-card>
          </el-col>

          <el-col :span="6">
            <el-row>
              <el-col :span="24">
                <el-card shadow="never" v-loading="loader">
                  <div slot="header" class="clearfix">
                    <span>商品类型</span>
                  </div>

                  <el-form-item label="是否套装商品">
                    <el-switch v-model="form.is_package_items" active-color="#13ce66" inactive-color="#ff4949"
                      @change="packageItemsChange">
                    </el-switch>
                  </el-form-item>

                  <el-form-item label="*是否为赠品">
                    <el-switch v-model="form.is_gift" active-color="#13ce66" inactive-color="#ff4949"
                      @change="giftStatusChange">
                    </el-switch>
                    <el-tag type="danger">开启后前台不可购买</el-tag>
                    <!--                    <el-alert title="开启后前台不可购买" type="info" :closable="false" show-icon></el-alert>-->
                  </el-form-item>

                  <el-form-item label="*是否为教育优惠商品">
                    <el-switch v-model="form.is_edu" active-color="#13ce66" @change="eduStatusChange">
                    </el-switch>
                  </el-form-item>

                  <el-form-item label="*是否为企业购商品">
                    <el-switch v-model="form.is_ec" active-color="#13ce66" @change="ecBuyChange">
                    </el-switch>
                  </el-form-item>

                  <el-form-item label="*特殊商品类型">
                    <template>
                      <div>
                        <el-radio-group v-model="form.special_value" size="mini" @change="teirStatusChange">
                          <el-radio-button label="normal">普通商品</el-radio-button>
                          <el-radio-button label="acplus">AC+</el-radio-button>
                          <el-radio-button label="iup">iUP</el-radio-button>
                        </el-radio-group>
                      </div>
                    </template>
                  </el-form-item>

                  <!-- <el-form-item label="*是否AC+商品">
                  <el-switch v-model="form.is_acplus" active-color="#13ce66" @change="acStatusChange">
                  </el-switch>
                </el-form-item> -->
                </el-card>
              </el-col>
            </el-row>

            <el-row>
              <el-col>
                <el-card shadow="never" v-loading="loader">
                  <div slot="header" class="clearfix">
                    <span>商品属性</span>
                  </div>

                  <el-form-item label="是否主品">
                    <el-switch
                      v-model="form.is_main_product"
                      active-color="#13ce66"
                      :active-value="1"
                      :inactive-value="0">
                    </el-switch>
                  </el-form-item>
                </el-card>
              </el-col>
            </el-row>

            <el-row>
              <el-col>
                <el-card shadow="never" v-loading="loader">
                  <div slot="header" class="clearfix">
                    <span>其他</span>
                  </div>

                  <el-form-item label="*是否为仅自提商品">
                    <el-switch v-model="form.is_ziti" active-color="#13ce66" @change="zitiStatusChange">
                    </el-switch>
                  </el-form-item>

                  <el-form-item label="是否可搭售">
                    <el-switch v-model="form.is_addcart_goods" active-color="#13ce66" @change="autoIsGoodsChange">
                    </el-switch>
                  </el-form-item>

                  <el-form-item label="*企业微信是否可分享">
                    <el-switch v-model="form.wecom_share" active-color="#13ce66" @change="wecomShareStatusChange">
                    </el-switch>
                  </el-form-item>

                  <el-form-item label="*是否支持自动接单">
                    <el-switch v-model="form.auto_receiving" active-color="#13ce66" @change="autoReceivingChange">
                    </el-switch>
                  </el-form-item>
                </el-card>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <!-- <el-card shadow="never" v-loading="loader">
          <div slot="header" class="clearfix">
            <span>是否特殊商品类型</span>
            <el-button style="float: right; padding: 3px 0" type="text" @click="panelCollapse('goods_type')"><i class="iconfont" :class="panel.goods_type ? 'icon-window-minimize1': 'icon-plus'"></i></el-button>
          </div>
          <el-radio-group v-model="form.special_type">
            <el-radio label="normal">普通商品</el-radio>
            <el-radio label="drug">处方药</el-radio>
          </el-radio-group>
        </el-card> -->
        <el-card shadow="never" v-loading="loader">
          <div slot="header" class="clearfix">
            <span>商品参数</span>
            <el-button style="float: right; padding: 3px 0" type="text" @click="panelCollapse('param')"><i
                class="iconfont" :class="panel.param ? 'icon-window-minimize1' : 'icon-plus'"></i></el-button>
          </div>
          <transition name="el-zoom-in-top">
            <div class="form-collapse" v-show="panel.param">
              <el-row :gutter="20">
                <el-col :xs="24" :sm="12" :md="8" :lg="6" v-for="(item, index) in params" :key="index">
                  <el-form-item :label="item.label">
                    <el-select v-if="item.children.length > 0" v-model="form.item_params[index].attribute_value_id"
                      placeholder="请选择" @change="paramsChange">
                      <el-option v-for="child in item.children" :key="child.value" :label="child.label"
                        :value="child.value">
                      </el-option>
                    </el-select>
                    <el-input v-else v-model="form.item_params[index].attribute_value_name" :maxlength="60"
                      placeholder=""></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
          </transition>
        </el-card>
        <el-card shadow="never" v-loading="loader">
          <div slot="header" class="view-flex">
            <div class="view-flex-item">商品规格 &nbsp;&nbsp;&nbsp;&nbsp;<el-tag type="danger"
                v-if="this.form.is_ec">请注意：Ec商品的【原始货号】需要与原始商品【货号】相对应</el-tag></div>
            <template v-if="!isEditor">
              <span class="small mark" v-if="skus.length === 0">添加多规格商品请先为当前主类目绑定规格!</span>
              <template v-if="skus.length > 0">
                <el-switch style="margin-left: 30px;" v-model="form.nospec" active-color="#13ce66"
                  inactive-color="#efefef" active-text="统一规格" inactive-text="多规格">
                </el-switch>
              </template>
            </template>
            <template v-if="!form.nospec">
              <span style="margin-left: 30px;">
                <span style="margin-right: 10px;">是否在商详页成列图片规格</span>
                <el-switch v-model="form.is_show_specimg" active-color="#13ce66" inactive-color="#efefef">
                </el-switch>
              </span>
            </template>
          </div>
          <template v-if="form.nospec">
            <el-row :gutter="20">
              <el-col :xs="24" :sm="12" :md="8">
                <el-form-item label="商品状态" :render-header="renderRequire">
                  <el-select v-model="form.approve_status" placeholder="请选择">
                    <el-option v-for="item in statusOption" :key="item.value" :label="item.title" :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="8" v-if="!form.is_package_items && login_type != 'disti'">
                <el-form-item label="库存">
                  <el-input type="number" required min="0" v-model="form.store" placeholder=""></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="8">
                <el-form-item label="商品货号">
                  <el-input v-model="form.item_bn" :maxlength="60" placeholder=""></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="8"> <!-- v-if="form.is_edu" -->
                <el-form-item label="原始货号">
                  <el-input v-model="form.origin_bn" :maxlength="60" placeholder=""></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="8">
                <el-form-item label="重量">
                  <el-input type="number" required min="0" v-model="form.weight" placeholder=""><template
                      slot="append">kg</template></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="8">
                <el-form-item label="体积">
                  <el-input type="number" required min="0" v-model="form.volume" placeholder=""><template
                      slot="append">m³</template></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="8">
                <el-form-item label="销售参考价">
                  <el-input type="number" required min="0" v-model="form.price" placeholder=""><template
                      slot="prepend">¥</template></el-input>
                </el-form-item>
              </el-col>
<!--              <el-col :xs="24" :sm="12" :md="8">-->
<!--                <el-form-item label="成本价">-->
<!--                  <el-input type="number" required min="0" v-model="form.cost_price" placeholder=""><template-->
<!--                      slot="prepend">¥</template></el-input>-->
<!--                </el-form-item>-->
<!--              </el-col>-->
<!--              <el-col :xs="24" :sm="12" :md="8">-->
<!--                <el-form-item label="原价">-->
<!--                  <el-input type="number" required min="0" v-model="form.market_price" placeholder=""><template-->
<!--                      slot="prepend">¥</template></el-input>-->
<!--                </el-form-item>-->
<!--              </el-col>-->
              <el-col :xs="24" :sm="12" :md="8">
                <el-form-item label="SKU描述">
                  <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 8 }" :maxlength="350"
                    v-model="form.sku_describe"></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="8">
                <el-form-item label="是否为以旧换新商品">
                  <el-switch
                    v-model="form.is_old"
                    active-color="#13ce66" @change="oldStatusChange">
                  </el-switch>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="8" v-if="form.point_access == 'items'">
                <el-form-item label="获取积分">
                  <el-input type="number" required min="0" v-model="form.point_num" placeholder=""></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </template>
          <template v-else>
            <draggable
            v-model="skus"
            class="sku-item"
            @sort="onStart">
              <el-card v-for="(item, index) in skus" :key="index">
                <div class="sku-select__item">
                  <div class="goods">
                    {{ item.sku_name }}：
                  </div>
                  <div class="sku-select__checkgroup">
                    <el-checkbox-group v-model="item.checked_sku" @change="handleSkuChange">
                      <template v-for="(value, vn) in item.sku_value">
                        <div class="sku-select__checkitem" :key="vn + 'value'">
                          <imgBox v-if="value.image_url" :imgUrl="value.image_url" width="50" height="50" />
                          <el-checkbox :label="value.attribute_value_id">
                            <el-input v-if="item.checked_sku.indexOf(value.attribute_value_id) !== -1"
                              v-model="value.custom_attribute_value" size="mini" style="width: 100px;"
                              @change="handleSkuName(value.custom_attribute_value, value.attribute_value_id)"></el-input>
                            <span v-else>{{ value.attribute_value }}</span>
                          </el-checkbox>
                        </div>
                      </template>
                    </el-checkbox-group>
                  </div>
                </div>
              </el-card>
            </draggable>

            <div v-if="specImages.length > 0" class="content-bottom-padded">
              <div class="content-padded h3">设置规格图片</div>
              <el-table :data="specImages" :header-cell-style="{ background: '#f5f7fa' }">
                <el-table-column label="规格" prop="item_spec" width="240">
                </el-table-column>
                <el-table-column label="规格图">
                  <template slot-scope="scope">
                    <draggable v-model="scope.row.item_image_url" :options="{
                      animation: 300,
                      forceFallback: false,
                      scroll: true,
                      draggable: '.drag-img-box'
                    }" class="components-view">
                      <imgBox v-for="(item, index) in scope.row.item_image_url" :key="index" :imgUrl="item" inline
                        class="drag-img-box" removeBtn width="50" height="50"
                        @remove="handleImgRemove(scope.$index, index)"></imgBox>
                      <imgBox width="50" height="50" inline @click="handleSkuImg(scope.$index)"></imgBox>
                    </draggable>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="content-padded h3">设置规格</div>
            <div class="content-padded" style="display: flex;">
              <div style="margin-right: 12px">
                <span class="mark">
                  *默认选择商品编码：
                </span>
                <el-popover
                  placement="top-start"
                  trigger="hover"
                  content="用户进入商详页以及结算页默认选中的规格"
                >
                  <span slot="reference" style="margin-right: 4px;"><i class="el-icon-info"></i></span>
                </el-popover>
                <el-input
                  class="input-m"
                  v-model="form.default_select_item_bn"
                  size="mini"
                  placeholder="请输入商品编码"
                />
              </div>
              <div>
                <span class="mark">*默认展示商品编码：</span>
                <el-popover
                  placement="top-start"
                  trigger="hover"
                  content="商品货架默认显示的商品"
                >
                  <span slot="reference" style="margin-right: 4px;"><i class="el-icon-info"></i></span>
                </el-popover>
                <el-input
                  class="input-m"
                  v-model="form.default_spu_item_bn"
                  size="mini"
                  placeholder="请输入商品编码"
                />
              </div>
            </div>
            <el-table :data="bulkFilling" :show-header="false" :highlight-current-row="false">
              <el-table-column>
                <template slot-scope="scope">
                  {{ scope.row.custom_attribute_value || scope.row.item_spec }}
                </template>
              </el-table-column>
              <el-table-column label="前台使用">
                <template slot-scope="scope">
                  <el-select v-model="scope.row.is_use" size="mini" placeholder="请选择">
                    <el-option v-for="item in isNoOption" :key="item.value" :label="item.label" size="mini"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column label="*状态">
                <template slot-scope="scope">
                  <el-select v-model="scope.row.approve_status" size="mini" placeholder="请选择">
                    <el-option v-for="item in statusOption" :key="item.value" :label="item.title" size="mini"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column label="库存" width="100" v-if="!form.is_package_items && login_type != 'disti'">
                <template slot-scope="scope">
                  <el-input type="number" required min="0" v-model="scope.row.store" size="mini"
                    placeholder=""></el-input>
                </template>
              </el-table-column>
              <el-table-column label="货号"  width="145">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.item_bn" :maxlength="60" size="mini" placeholder=""></el-input>
                </template>
              </el-table-column>
              <el-table-column label="原始货号" width="145"> <!-- v-if="form.is_edu" -->
                <template slot-scope="scope">
                  <el-input v-model="scope.row.origin_bn" :maxlength="60" size="mini" placeholder=""></el-input>
                </template>
              </el-table-column>
              <el-table-column label="重量" width="90">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.weight" :maxlength="60" size="mini" placeholder=""></el-input>
                </template>
              </el-table-column>
              <!--              <el-table-column label="体积">-->
              <!--                <template slot-scope="scope">-->
              <!--                  <el-input v-model="scope.row.volume" :maxlength="60" size="mini" placeholder=""></el-input>-->
              <!--                </template>-->
              <!--              </el-table-column>-->
              <el-table-column label="销售参考价">
                <template slot-scope="scope">
                  <el-input type="number" required min="0" v-model="scope.row.price" size="mini"
                    placeholder=""></el-input>
                </template>
              </el-table-column>
<!--              <el-table-column label="成本价">-->
<!--                <template slot-scope="scope">-->
<!--                  <el-input type="number" required min="0" v-model="scope.row.cost_price" size="mini"-->
<!--                    placeholder=""></el-input>-->
<!--                </template>-->
<!--              </el-table-column>-->
<!--              <el-table-column label="原价">-->
<!--                <template slot-scope="scope">-->
<!--                  <el-input type="number" required min="0" v-model="scope.row.market_price" size="mini"-->
<!--                    placeholder=""></el-input>-->
<!--                </template>-->
<!--              </el-table-column>-->
              <el-table-column label="SKU描述" width="150">
                <template slot-scope="scope">
                  <el-input type="textarea" v-model="scope.row.sku_describe" :autosize="{ minRows: 2, maxRows: 8 }"
                    :maxlength="350"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="以旧换新"  width="100">
                <template slot-scope="scope" >
                  <el-select v-model="scope.row.is_old" size="mini" placeholder="请选择">
                    <el-option label="是" size="mini" :value="1" />
                    <el-option label="否" size="mini" :value="0" />
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column label="获取积分" v-if="form.point_access == 'items'">
                <template slot-scope="scope">
                  <el-input type="number" required min="0" v-model="scope.row.point_num" size="mini"
                    placeholder=""></el-input>
                </template>
              </el-table-column>
              <el-table-column width="80">
                <template slot-scope="scope">
                  <el-button type="primary" @click="fillSku" size="mini">填充</el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-table :data="specItems[currentPage - 1]" :header-cell-style="{ background: '#f5f7fa' }"
              style="width: 100%">
              <el-table-column label="规格值">
                <template slot-scope="scope">
                  <span v-for="(item, index) in scope.row.item_spec" :key="index">
                    {{ item.spec_custom_value_name || item.spec_value_name }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column label="前台使用">
                <template slot-scope="scope">
                  <el-select v-model="scope.row.is_use" size="mini" placeholder="请选择" @change="upadateState(scope.row)">
                    <el-option v-for="item in isNoOption" :key="item.value" :label="item.label" size="mini"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column label="状态" :render-header="renderRequire">
                <template slot-scope="scope">
                  <el-select v-model="scope.row.approve_status" size="mini" placeholder="请选择"
                    @change="upadateState(scope.row)">
                    <el-option v-for="item in statusOption" :key="item.value" :label="item.title" size="mini"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column label="库存" v-if="!form.is_package_items && login_type != 'disti'"
                :render-header="renderRequire" width="100">
                <template slot-scope="scope">
                  <el-input type="number" required min="0" v-model="scope.row.store" size="mini" placeholder=""
                    @change="upadateState(scope.row)"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="货号" :render-header="renderRequire" width="145" style="margin-left: -15px;">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.item_bn" :maxlength="120" size="mini" placeholder=""
                    @change="upadateState(scope.row)" style="width: 108%"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="原始货号" width="145" style="margin-left: -15px;"> <!-- v-if="form.is_edu" -->
                <template slot-scope="scope">
                  <el-input v-model="scope.row.origin_bn" :maxlength="120" size="mini" placeholder=""
                    @change="upadateState(scope.row)" style="width: 108%"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="重量(kg)" width="90">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.weight" :maxlength="60" size="mini" placeholder=""
                    @change="upadateState(scope.row)"></el-input>
                </template>
              </el-table-column>
              <!--              <el-table-column label="体积(m³)">-->
              <!--                <template slot-scope="scope">-->
              <!--                  <el-input v-model="scope.row.volume" :maxlength="60" size="mini" placeholder="" @change="upadateState(scope.row)"></el-input>-->
              <!--                </template>-->
              <!--              </el-table-column>-->
              <el-table-column label="销售参考价" :render-header="renderRequire">
                <template slot-scope="scope">
                  <el-input type="number" required min="0" v-model="scope.row.price" size="mini" placeholder=""
                    @change="upadateState(scope.row)"></el-input>
                </template>
              </el-table-column>
<!--              <el-table-column label="成本价">-->
<!--                <template slot-scope="scope">-->
<!--                  <el-input type="number" required min="0" v-model="scope.row.cost_price" size="mini" placeholder=""-->
<!--                    @change="upadateState(scope.row)"></el-input>-->
<!--                </template>-->
<!--              </el-table-column>-->
<!--              <el-table-column label="原价">-->
<!--                <template slot-scope="scope">-->
<!--                  <el-input type="number" required min="0" v-model="scope.row.market_price" size="mini" placeholder=""-->
<!--                    @change="upadateState(scope.row)"></el-input>-->
<!--                </template>-->
<!--              </el-table-column>-->
              <el-table-column label="SKU描述" width="150">
                <template slot-scope="scope">
                  <el-input type="textarea" v-model="scope.row.sku_describe" :maxlength="350"
                    :autosize="{ minRows: 2, maxRows: 8 }" @change="upadateState(scope.row)"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="以旧换新"  width="100">
                <template slot-scope="scope">
                  <el-select v-model="scope.row.is_old" size="mini" placeholder="请选择" @change="upadateState(scope.row)">
                    <el-option label="是" size="mini" :value="1" />
                    <el-option label="否" size="mini" :value="0" />
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column label="获取积分" v-if="form.point_access == 'items'">
                <template slot-scope="scope">
                  <el-input type="number" required min="0" v-model="scope.row.point_num" size="mini" placeholder=""
                    @change="upadateState(scope.row)"></el-input>
                </template>
              </el-table-column>
              <el-table-column width="80">
                <template slot-scope="scope">
                  <el-button type="text" @click="clearSku(scope.$index)">清除</el-button>
                </template>
              </el-table-column>
            </el-table>
            <div class="content-center content-top-padded">
              <el-pagination :current-page="currentPage" :page-size="specPagesize" layout="prev, pager, next"
                @current-change="pageChange" :total="specTotal">
              </el-pagination>
            </div>
          </template>
        </el-card>

        <el-card header="推荐商品" shadow="never" v-loading="loader" v-if="this.showAC"> <!--!this.form.is_edu && -->
          <el-button type="primary" class="el-icon-plus" @click="relItems" size="mini">选择商品</el-button>
          <el-table v-if="(form.recommend_items && form.recommend_items.length > 0)" :data="form.recommend_items"
            style="line-height: normal">
            <el-table-column label="ID" prop="item_id" width="60"></el-table-column>
            <el-table-column label="名称" prop="item_name"></el-table-column>
            <el-table-column label="排序（升序）" width="150">
              <template slot-scope="scope">
                <el-input v-model="scope.row.sort" size="mini"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="50">
              <template slot-scope="scope">
                <i class="iconfont icon-trash-alt"
                  @click="deleteItemRow(scope.$index, scope.row.goods_id)"></i>
              </template>
            </el-table-column>
          </el-table>
        </el-card>




        <el-card header="AC+/iUP商品" shadow="never" v-loading="loader" v-if="this.showAC"> <!--!this.form.is_edu && -->
          <el-button type="primary" class="el-icon-plus" @click="relAcItems" size="mini">选择商品</el-button>

          <el-table v-if="(form.ac_items && form.ac_items.length > 0)" :data="form.ac_items" style="line-height: normal">
            <!--            <el-table-column label="ID" prop="item_id" width="60"></el-table-column>-->
            <el-table-column label="名称" prop="item_name"></el-table-column>
            <!--            <el-table-column label="规格值" prop="item_spec_desc"></el-table-column>-->
            <!--            <el-table-column label="货号" prop="item_bn"></el-table-column>-->
            <!--            <el-table-column label="销售价" prop="price"></el-table-column>-->
            <el-table-column label="操作" width="50">
              <template slot-scope="scope">
                <i class="iconfont icon-trash-alt"
                  @click="deleteAcItemRow(scope.$index, form.ac_items, scope.row.goods_id)"></i>
              </template>
            </el-table-column>
          </el-table>
          <el-table v-if="(form.ac_items_sku && form.ac_items_sku.length > 0)" :data="form.ac_items_sku"
            style="line-height: normal">
            <!--            <el-table-column label="ID" prop="item_id" width="60"></el-table-column>-->
            <el-table-column label="名称" prop="item_name"></el-table-column>
            <el-table-column label="规格值" prop="item_spec_desc"></el-table-column>
            <el-table-column label="货号" prop="item_bn"></el-table-column>
            <el-table-column label="销售价" prop="price" :formatter="priceformatter"></el-table-column>
            <el-table-column label="排序" width="80">
              <template slot-scope="scope">
                <el-input v-model="scope.row.sort" size="mini"></el-input>
              </template>
            </el-table-column>
            <!--            <el-table-column label="操作" width="50">-->
            <!--              <template slot-scope="scope">-->
            <!--                <i class="iconfont icon-trash-alt" @click="deleteAcItemRow(scope.$index, form.ac_items)"></i>-->
            <!--              </template>-->
            <!--            </el-table-column>-->
          </el-table>
        </el-card>

        <el-card v-if="form.type === 1" header="跨境参数" shadow="never" v-loading="loader">
          <el-row style="margin-bottom:0px;">
            <el-col :xs="24" :sm="12" :md="8">
              <el-form-item label="商品税率">
                <el-input type="number" :step="0.1" :min="0" :max="100" v-model="form.crossborder_tax_rate"
                  :controls="false" :precision="2"><template slot="append">%</template></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row style="margin-bottom:0px;">
            <el-col :xs="24" :sm="12" :md="8">
              <el-form-item label="产地国家">
                <el-select v-model="form.origincountry_id" filterable clearable placeholder="请选择">
                  <el-option v-for="item in origincountry" :key="item.origincountry_id" :label="item.origincountry_name"
                    :value="item.origincountry_id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row style="margin-bottom:0px;">
            <el-col :xs="24" :sm="12" :md="8">
              <el-form-item label="税费策略">
                <el-select v-model="form.taxstrategy_id" filterable clearable placeholder="请选择">
                  <el-option v-for="item in taxstrategy" :key="item.id" :label="item.taxstrategy_name" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row style="margin-bottom:0px;">
            <el-col :xs="24" :sm="12" :md="8">
              <el-form-item v-if="form.taxstrategy_id !== '0'" label="单位份数">
                <el-input type="number" min="1" v-model="form.taxation_num" :controls="false"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

        </el-card>

        <el-card v-if="false" header="TDK设置" shadow="never" v-loading="loader">
          <el-form-item label="TITLE(页面标题）">
            <el-input type="text" v-model="tdk_info.title"></el-input>
          </el-form-item>
          <el-form-item label="MATE_DESCRIPTION(页面描述）">
            <el-input type="textarea" v-model="tdk_info.mate_description"></el-input>
          </el-form-item>
          <el-form-item label="MATE_KEYWORDS(关键词）">
            <el-input type="textarea" v-model="tdk_info.mate_keywords"></el-input>
            <span class="tip">关键词之间请用半角”,”分隔</span>
          </el-form-item>
        </el-card>

        <el-card header="图文详情" shadow="never" v-loading="loader">
          <el-form-item label="模式">
            <el-radio-group v-model="mode">
              <el-radio :label="'richText'">富文本</el-radio>
              <el-radio :label="'component'">组件式</el-radio>
            </el-radio-group>
          </el-form-item>
          <template v-if="mode === 'richText'">
            <vue-html5-editor :content="form.intro.toString()" ref="editor" @change="updateContent"
              :height="360"></vue-html5-editor>
            <span class="tpl_item img" @click="addImgPreview" style="">
              <i class="iconfont icon-image"></i>图片
            </span>
          </template>
          <template v-else>
            <richTextEditor :data="content" :control="['film', 'slider', 'heading', 'writing']" @change="handleContent" />
          </template>
          <imgPicker :dialog-visible="thumbDialog" :sc-status="isGetThumb" :isMost="true" @chooseImg="pickThumb"
            @closeImgDialog="closeThumbDialog"></imgPicker>
        </el-card>
        <div class="section-footer with-border content-center">
          <el-button @click.native="handleCancel">取消</el-button>
          <el-button type="primary" @click="submitItemsActionConfirm" :loading="submitLoading">{{ submitLoading ? '提交中' :
            '保存' }}</el-button>
        </div>
      </template>
    </el-form>
    <GoodsSelect :items-visible="itemVisible" :get-status="setItemStatus" :item-tier="itemNormal" is_addcart_goods="1" :rel-items-ids="relItemsIds" @chooseStore="chooseItemsAction" @closeStoreDialog="closeItemDialogAction" :main-goods-is-edu="this.form.is_edu"></GoodsSelect> <!--推荐商品-->
    <GoodsSelectOne :items-visible="itemAcVisible" :is-spu=true :item-tier="itemTierAc" :get-status="setAcItemStatus" :rel-items-ids="acIds" :main-goods-is-edu="this.form.is_edu" @chooseStore="chooseAcItemsAction" @closeStoreDialog="closeAcItemDialogAction"></GoodsSelectOne> <!--AC+-->
  </div>
</template>

<script>
  import store from '@/store'
  import { mapGetters } from 'vuex'
  // import the component
  import Treeselect from '@riophae/vue-treeselect'
  import draggable from 'vuedraggable'
  // import the styles
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'
  import { getItemsDetail, createItems, updateItems, getCategory, getGoodsAttr, getCategoryInfo, getItemsList } from '@/api/goods'
  import { getShippingTemplatesList } from '@/api/shipping'
  import { uploadMaterial } from '@/api/wechat'
  import imgPicker from '@/components/imageselect'
  import videoPicker from '@/components/videoselect'
  import richTextEditor from '@/components/function/richTextEditor'
  import imgBox from '@/components/element/imgBox'
  import GoodsSelect from '@/components/goodsSelect'
  import GoodsSelectOne from '@/components/goodsSelect'
  import district from '@/common/district.json'
  import { getOrigincountry, getTaxstrategyList } from '../../../../api/crossborder'
  import { getPointRule } from '../../../../api/promotions'
  import { dragIssuesOptions } from '@/consts'
  import { deepCopy } from '@/utils/deepCopy'

export default {
  inject: ['refresh'],
  components: {
    imgPicker,
    videoPicker,
    Treeselect,
    draggable,
    richTextEditor,
    imgBox,
    GoodsSelect,
    GoodsSelectOne
  },
  data() {
    return {
      radio: '普通',
      dragIssuesOptions,
      hidden: false,
      itemVisible: false,
      itemAcVisible: false,
      setItemStatus: false,
      setAcItemStatus: false,
      itemTierAc: [
        'acplus',
        'iup'
      ],
      itemNormal: [
        'normal'
      ],
      show_sideBar: false,
      relItemsIds: [],
      acIds: [],
      list: [],
      total_count: 0,
      // 跨境设置
      origincountry: [],    // 产地国
      taxstrategy: [],    // 税费策略

      isGift: false,
      showAC: true,
      itemVideo: {},
      select_regions_value: [],
      regions: district,
      mainCategory: [],
      selectedMainCategory: [],
      categoryNames: [],
      mainCateLoader: false,
      loader: true,
      submitLoading: false,
      isEditor: false,
      isLeave: false,
      panel: {
        base: true,
        param: true,
        goods_type: true,
      },
      is_new: false,
      isGetImage: false,
      imgDialog: false,
      isGetVideo: false,
      isNoOption: [
        {
          label: '是',
          value: '1'
        },
        {
          label: '否',
          value: '0'
        },
      ],
      statusOption: [
        {
          title: '前台可销售',
          value: 'onsale'
        },
        // {
        //   title: '可线下销售',
        //   value: 'offline_sale'
        // },
        {
          title: '前台仅展示',
          value: 'only_show'
        },
        {
          title: '不可销售',
          value: 'instock'
        }
      ],
      categoryList: [],
      brandList: [],
      content: [],
      form: {
        item_id: '',
        item_type: 'normal',
        special_type: 'normal',
        item_source: 'mall',
        item_category: [],
        item_params: [],
        item_name: '',
        sort: 0,
        tax_rate: 0,
        item_bn: '',
        origin_bn: '',
        brief: '',
        item_en_name: '',
        item_tag_name: '',
        weight: '',
        volume: '',
        price: '',
        market_price: '',
        sku_describe: '',
        cost_price: 0,
        barcode: '',
        item_unit: '',
        rebate: '',
        store: '',
        brand_id: '',
        templates_id: 1,
        approve_status: 'onsale',
        intro: '',
        pics: [],
        homeimg: [],
        videos: '',
        videos_url: '',
        video_pic_url: '',
        video_auto_play: '',
        nospec: true,
        is_show_specimg: false,
        spec_images: [],
        spec_items: [],
        item_main_cat_id: '',
        is_gift: false,
        crossborder_tax_rate: '',
        origincountry_id: 0,
        taxstrategy_id: '0',
        taxation_num: 1,
        type: 0,
        shustore: [],
        is_profit: true,
        point_access: 'order',
        point_num: 0,
        is_package_items: false,
        tdk_content: '',
        is_appointment: false,
        is_edu: false,
        special_value: 'normal',
        is_ziti: false,
        is_addcart_goods: true,
        wecom_share: true,
        is_old: false,
        is_ec: false,
        auto_receiving: true,
        appointment_start: '',
        appointment_end: '',
        appointment_time: [],
        recommend_items: [],
        ac_items: [],
        ac_items_sku: [],
        home_pic: '',
        default_select_item_bn: '',
        default_spu_item_bn: '',
        is_main_product: '0'
      },
      homeimgDialog: false,
      videoImgDialog: false,
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime());
            end.setTime(end.getTime() + 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime());
            end.setTime(end.getTime() + 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime());
            end.setTime(end.getTime() + 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      tdk_info: {
        title: '',
        mate_description: '',
        mate_keywords: ''
      },
      bulkFilling: [{
        item_spec: '批量填充',
        is_use: '1',
        approve_status: '',
        store: '',
        item_bn: '',
        origin_bn: '',
        price: '',
        cost_price: '',
        market_price: '',
        sku_describe: '',
        barcode: '',
        point_num: '',
        is_old: ''
      }],
      specPagesize: 10,
      specTotal: 0,
      specImages: [],
      specItems: [],
      templatesList: [],
      picsList: [],
      params: [],
      skus: [],
      templatesListParams: {
        page: 1,
        pageSize: 99
      },
      dialogVisible: false,
      dialogImageUrl: '',
      thumbDialog: false,
      isGetThumb: false,
      isGetPics: false,
      picsDialog: false,
      picsCurrent: -1,
      picsOldLen: 0,
      currentSku: -1,
      currentPage: 1,
      multiple: false,
      mode: 'richText',
      list_params: {
        page: 1,
        pageSize: 20,
        distributor_id: '',
        is_warning: false,
        keywords: '',
      },
      is_delivery_goods: 0,
      as_recommend: [],
      spec_items: [], // 接口返回的规则商品数据
    }
  },
  computed: {
    ...mapGetters([
      'editingSkus',
      'login_type'
    ])
  },
  methods: {
    pickImg(data) {
      this.form.home_pic = data.url
      this.homeimgDialog = false
    },
    closeHomeImgDialog() {
      this.homeimgDialog = false
    },
    handleHomeImgChange() {
      this.homeimgDialog = true
      this.isGetImage = true
    },
    pickVideoImg(data) {
      this.form.video_pic_url = data.url
      this.videoImgDialog = false
    },
    closeVideoImgDialog() {
      this.videoImgDialog = false
    },
    handleVideoImgChange() {
      this.videoImgDialog = true
      this.isGetImage = true
    },
    chooseItemsAction(data) {
      this.itemVisible = false
      this.relItemsIds = data
      console.log('data', this.relItemsIds)
      console.log(data, this.form.recommend_items, 222)
      /* if (this.form.recommend_items.length > 0 && data.length <= 0) {
        data = this.form.recommend_items
      } */
      if (data === null || data.length <= 0) return
      let arr = []
      data.forEach((item, index) => {
        let newData = ''
        let isInArr = this.form.recommend_items.findIndex((n) => n.item_id == item.item_id)
        if (isInArr == -1) {
          newData = {
            item_id: item.item_id,
            itemId: item.item_id,
            item_name: item.item_name,
            itemName: item.item_name,
            sort: item.sort,
          }
        } else {
          newData = this.form.recommend_items[index]
        }
        arr.push(newData)
      })
      this.form.recommend_items = arr
    },
    chooseAcItemsAction(data) {
      this.itemAcVisible = false
      this.acIds = data
      console.log('data', this.relItemsIds)
      console.log(data, this.form.ac_items, 333)
      /* if (this.form.recommend_items.length > 0 && data.length <= 0) {
        data = this.form.recommend_items
      } */
      if (data === null || data.length <= 0) return
      // if (data.length > 1) {
      //   this.$message({message: 'ac+商品只能选择一个', type: 'error'})
      //   return
      // }
      let arr = []
      let spu_arr = []

      data.forEach((item, index) => {
        let newData = ''
        let isInArr = this.form.ac_items.findIndex((n) => n.item_id == item.item_id)
        if (isInArr == -1) {
          newData = {
            item_id: item.item_id,
            itemId: item.item_id,
            item_name: item.item_name,
            itemName: item.item_name,
            goods_id: item.goods_id,
            // sort: item.sort,
            // price: item.price,
            // sku: item.item_spec_desc,
            // item_bn: item.item_bn
          }
        } else {
          newData = this.form.ac_items[index]
        }
        spu_arr.push(newData)
        item.sku_list.forEach((sku, index) => {
          let newDataSku = ''
          newDataSku = {
            item_id: sku.item_id,
            itemId: sku.item_id,
            item_name: sku.item_name,
            itemName: sku.item_name,
            sort: sku.sort ? sku.sort : 0,
            price: sku.price,
            item_spec_desc: sku.item_spec_desc,
            item_bn: sku.item_bn,
            goods_id: item.goods_id
          }
          arr.push(newDataSku)
        })
      })

      this.form.ac_items = spu_arr
      this.form.ac_items_sku = arr
    },
    closeItemDialogAction() {
      this.itemVisible = false
    },
    closeAcItemDialogAction() {
      this.itemAcVisible = false
    },
    relItems() {
      if (this.form.is_ec === true) {
        this.itemNormal = [
          'normal', 'ec'
        ]
      } else {
        this.itemNormal = [
          'normal'
        ]
      }
      this.itemVisible = true
      this.setItemStatus = true
      this.setAcItemStatus = false
      // this.relItemsIds = this.form.recommend_items
      console.log('推荐商品697', this.form.recommend_items, this.relItemsIds)
    },
    relAcItems() {
      this.itemAcVisible = true
      this.setItemStatus = false
      this.setAcItemStatus = true
      // this.relItemsIds = this.form.recommend_items
      console.log('ac商品', this.form.ac_items, this.acIds)
      console.log('ac商品sku', this.form.ac_items_sku, this.acIds)
    },
    deleteItemRow(index, goods_id) {
      console.log('deleteItemRow', goods_id)
      // this.form.recommend_items.forEach((item, index1) => {
      //   if (item.goods_id === goods_id) {
      //     this.form.recommend_items.splice(index1, 1)
      //   }
      // })


      const new_list = this.form.recommend_items.filter(el => el.goods_id !== goods_id)
      this.form.recommend_items = new_list
      // rows.splice(index, 1)
      // console.log('deleteItemRow', index)
      // this.form.recommend_items = rows
      this.setItemStatus = false
      console.log('deleteItemRow', this.relItemsIds)
      this.relItemsIds.splice(index, 1)
    },
    deleteAcItemRow(index, rows, goods_id) {
      rows.splice(index, 1)
      console.log('deleteAcItemRow', goods_id)
      console.log('deleteAcItemRow', index)
      this.form.ac_items = rows
      // this.form.ac_items_sku = []
      this.setAcItemStatus = false
      this.acIds.splice(index, 1)


      console.log('deleteAcItemRow', this.form.ac_items_sku, this.form.ac_items_sku.length)
      // this.form.ac_items_sku.forEach((item, index1) => {
      //   console.log('deleteAcItemRow-index',index1)
      //   console.log('deleteAcItemRow-item',item)
      //   if (item.goods_id === goods_id) {
      //
      //     this.form.ac_items_sku.splice(index1, 1)
      //   }
      // })
      const new_list = this.form.ac_items_sku.filter(el => el.goods_id !== goods_id)
      this.form.ac_items_sku = new_list
      console.log('deleteAcItemRow', this.form.ac_items_sku)

    },
    dateChange(val) {
      console.log(val)
      if (this.form.is_appointment == true && val.length > 0) {
        this.form.appointment_start = this.dateStrToTimeStamp(val[0]) || new Date(val[0].replace(/\-/g, "/")).getTime() / 1000
        this.form.appointment_end = this.dateStrToTimeStamp(val[1]) || new Date(val[1].replace(/\-/g, "/")).getTime() / 1000
        // console.log(this.form.appointment_start, new Date(val[1].replace(/\-/g, "/")).getTime() / 1000, 931)
        // console.log(this.form.appointment_end, new Date(val[1].replace(/\-/g, "/")).getTime(), 932)
      } else {
        this.form.appointment_start = ''
        this.form.appointment_end = ''
        // console.log(this.form.appointment_start)
        // console.log(this.form.appointment_end)
      }

    },
    // 获取产地国
    getOrigincountry() {
      let where = { page: 1, pageSize: 99999 }
      if (this.form.type === 1) {
        getOrigincountry(where).then(res => {
          this.origincountry = res.data.data.list
          this.getTaxstrategyList()
        })
      }
    },
    // 获取税费策略
    getTaxstrategyList() {
      let where = { page: 1, pageSize: 99999 }
      if (this.form.type === 1) {
        getTaxstrategyList(where).then(res => {
          let listData = res.data.data.list
          listData.push({
            id: '0',
            taxstrategy_name: '不使用策略',
            created: '0',
            updated: '0'
          })
          this.taxstrategy = listData
        })
      }
    },
    regionChange() {
      this.form.regions_id = this.select_regions_value
      this.form.item_address_province = this.select_regions_value[0]
      this.form.item_address_city = this.select_regions_value[1]
    },
    fetchMainCate() {
      getCategory({ is_main_category: true }).then(res => {
        let list = []
        res.data.data.forEach(item => {
          let obj = {
            label: item.category_name,
            value: item.category_id,
            children: []
          }
          if (item.children.length > 0) {
            item.children.forEach(child => {
              let childObj = {
                label: child.category_name,
                value: child.category_id,
                children: []
              }
              obj.children.push(childObj)
              if (child.children.length > 0) {
                child.children.forEach(sub => {
                  let subObj = {
                    label: sub.category_name,
                    value: sub.category_id
                  }
                  childObj.children.push(subObj)
                })
              }
            })
          }
          list.push(obj)
        })
        this.mainCategory = list
        this.mainCateLoader = false
      })
    },
    handleCategoryChange(val) {
      getCategoryInfo(val[val.length - 1]).then(res => {
        let detail = res.data.data
        this.generateParams(detail.goods_params)
        this.generateSpec(detail.goods_spec)
      })
    },
    generateParams(data) {
      let params = []
      let formParams = []
      data.forEach(item => {
        let key = {
          value: item.attribute_id,
          label: item.attribute_name,
          children: []
        }
        item.attribute_values.list.forEach(child => {
          let val = {
            value: child.attribute_value_id,
            label: child.attribute_value
          }
          key.children.push(val)
        })
        params.push(key)
        let selected = this.form.item_params.find(n => item.attribute_id === n.attribute_id)
        formParams.push({
          attribute_id: item.attribute_id,
          attribute_value_id: selected ? selected.attribute_value_id : '',
          attribute_value_name: selected ? selected.attribute_value_name : ''
        })
      })
      this.params = params
      this.form.item_params = formParams
    },
    handleSkuName(val, id) {
      this.specItems.forEach(item => {
        item.forEach(child => {
          if (child.sku_id.indexOf(id) !== -1) {
            let index = child.item_spec.findIndex(n => id === n.spec_value_id)
            child.item_spec[index].spec_custom_value_name = val
            store.dispatch('setSku', child)
            this.updateSku()
          }
        })
      })
    },
    generateSpec(data) {
      let skus = []
      data.forEach(item => {
        let specs = []
        item.attribute_values.list.forEach(spec => {
          if (!spec.custom_attribute_value) {
            Object.assign(spec, { custom_attribute_value: spec.attribute_value })
          }
          specs.push(spec)
        })
        let sku = {
          sku_id: item.attribute_id,
          sku_name: item.attribute_name,
          is_image: item.is_image,
          sku_value: specs,
          checked_sku: []
        }
        skus.push(sku)
      })
      this.skus = skus
    },
    upadateState(data) {
      console.log('upadateState', data)
      store.dispatch('setSku', data)
    },
    panelCollapse(name) {
      this.panel[name] = !this.panel[name]
    },
    handleImgRemove(parent, index) {
      this.specImages[parent].item_image_url.splice(index, 1)
    },
    submitItemsAction: function () { // 提交商品
      if (+this.form.market_price < +this.form.rebate) {
        this.$confirm('当前店铺佣金大于商品原价, 是否继续保存?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then((res) => {
          this.submitItemsActionConfirm()
        }).catch(() => {
          return
        })
      } else {
        this.submitItemsActionConfirm()
      }
    },
    dateStrToTimeStamp(str) {
      return Date.parse(new Date(str)) / 1000
    },
    submitItemsActionConfirm() {
      console.log(this.form.is_appointment)
      console.log(this.form, 1101)
      console.log(this.form.appointment_end)

      const copyForm = deepCopy(this.form)
      const copyEditingSkus = deepCopy(this.editingSkus)
      if (copyForm.is_appointment == true && (!copyForm.appointment_start || !copyForm.appointment_end)) {
        this.$message({ message: '请选择预约时间', type: 'error' })
        return
      }

      this.submitLoading = true
      const that = this
      let formSkuItem = []
      if (this.editingSkus.length > 0) {
        formSkuItem = copyEditingSkus.filter(item => item.approve_status != '')
      }

      /*if (!copyForm.is_edu) {
      /*if (!this.form.is_edu) {
        this.form.origin_bn = ''
        if (this.editingSkus.length > 0) {
          this.editingSkus.forEach(item => item.origin_bn = '')
        }
      }
      if (!copyForm.is_edu) {
        copyForm.origin_bn = ''
        if (copyEditingSkus.length > 0) {
          copyEditingSkus.forEach(item => item.origin_bn = '')
        }
      }*/


      // this.specItems.forEach(item => {
      //   item.forEach(child => {
      //     formSkuItem.push(child)
      //   })
      // })
      if (this.mode === 'component') {
        copyForm.intro = JSON.stringify(this.content)
      }
      if (this.selectedMainCategory.length > 0) {
        copyForm.item_main_cat_id = this.selectedMainCategory[this.selectedMainCategory.length - 1]
      }
      if (!copyForm.nospec) {
        if (formSkuItem.length > 0) {
          const has_is_default = formSkuItem.map(f => f.is_default).includes(true)
          if(!has_is_default) formSkuItem[0].is_default = true
          // } else {
          //   this.submitLoading = false
          //   this.$message({message: '请设置规格信息', type: "error"})
          //   return
        }
      }
      if (copyForm.type === 1 && !copyForm.origincountry_id) {
        this.submitLoading = false
        this.$message({ message: '请选择产地国', type: 'error' })
        return
      }

      if (copyForm.taxstrategy_id !== '0' && copyForm.taxation_num < 1) {
        this.submitLoading = false
        this.$message({ message: '单位份数不可小于1', type: 'error' })
        return
      }
      copyForm.spec_images = JSON.stringify(this.specImages)
      copyForm.spec_items = JSON.stringify(formSkuItem)
      copyForm.tdk_content = JSON.stringify(this.tdk_info)
      if (copyForm.item_id && !this.is_new) {
        updateItems(copyForm.item_id, copyForm).then(response => {
          this.$message({
            message: '更新成功',
            type: 'success',
            duration: 2 * 1000,
            onClose() {
              that.submitLoading = false
              that.refresh()
              that.isLeave = true
              that.$router.go(-1)
            }
          })
        }).catch(error => {
          this.submitLoading = false
        })
      } else {
        createItems(copyForm).then(response => {
          this.$message({
            message: '添加成功',
            type: 'success',
            duration: 2 * 1000,
            onClose() {
              that.submitLoading = false
              that.refresh()
              that.isLeave = true
              that.$router.go(-1)
            }
          })
        }).catch(error => {
          this.submitLoading = false
        })
      }
    },
    updateContent: function (data) {
      this.form.intro = data
    },
    handleCancel: function () {
      this.$router.go(-1)
    },
    pageChange(val) {
      this.currentPage = val
    },
    // 详情中的上传图片
    addImgPreview: function () {
      this.thumbDialog = true
      this.isGetThumb = true
    },
    pickThumb: function (arr) {
      if (arr.length != 0) {
        arr.forEach(data => {
          if (data && data.url !== '') {
            this.thumbDialog = false
            var index = this.$refs.editor.$el.id
            var loc = this.$refs.editor
            var img = new Image()
            img.src = this.wximageurl + data.url
            if (loc.range) {
              loc.range.insertNode(img)
              var referenceNode = loc.range.endContainer
              if (referenceNode.className !== 'content') {
                loc.range.setStartAfter(referenceNode)
              } else {
                loc.range.setStart(loc.range.endContainer, loc.range.endOffset)
              }
            } else {
              loc.$refs.content.appendChild(img)
              loc.focus()
              loc.restoreSelection()
            }
            this.form.intro = loc.$refs.content.innerHTML
          }
        })
      }
    },
    closeThumbDialog: function () {
      this.thumbDialog = false
    },
    //品牌LOGO
    handleImgChange() {
      this.imgDialog = true
      this.isGetImage = true
    },
    closeImgDialog() {
      this.imgDialog = false
    },
    //视频
    pickVideo(data) {
      this.form.videos = data.media_id
      this.form.videos_url = data.url
    },
    //上传商品图（9张）
    handlePicsChange: function () {
      this.picsDialog = true
      this.isGetPics = true
      this.multiple = true
    },
    handleSkuImg(index) {
      this.currentSku = index
      this.picsDialog = true
      this.isGetPics = true
      this.multiple = true
    },
    pickPics(data) {
      if (this.currentSku === -1) {
        // if (this.picsOldLen + data.length >= 10) {
        //   this.$message.error('最多上传9张图片!')
        //   return false
        // }else {
        if (data.length != 0) {
          data.forEach(data => {
            if (data && data.url !== '') {
              this.form.pics.push(data.url)
              this.picsOldLen = this.form.pics.length
            }
          })
        }
        // }
      } else {
        // if (this.specImages[this.currentSku].item_image_url.length + data.length > 9) {
        //   this.$message.error('最多添加9张图片!')
        //   return false
        // }
        if (data.length > 0) {
          data.forEach(data => {
            if (data && data.url !== '') {
              this.specImages[this.currentSku].item_image_url.push(data.url)
            }
          })
        }
        this.currentSku = -1
      }
      this.picsDialog = false
    },
    fillSku() {
      let obj = { ...this.bulkFilling[0] }
      let newObj = {}
      for (let key in obj) {
        if (obj[key] && key !== 'item_spec') {
          newObj[key] = obj[key]
        }
        if (!obj[key] && key == 'is_old') { // 为了兼容批量填充，以旧换新选否，填充失败
          newObj[key] = 0
        }
      }
      // let list = [...this.specItems[this.currentPage-1]]
      let list = [...this.specItems]
      list.forEach((item, index) => {
        let arr = []
        item.forEach((i, x) => {
          i = { ...i, ...newObj }
          arr.push(i)
        })
        list[index] = arr
        // Object.assign(item, newObj)
      })
      this.specItems = list
      store.dispatch('setPage', list.flat())
    },
    clearSku(index) {
      this.$confirm('确定清除当前规格的数据吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let skuItem = this.specItems[this.currentPage - 1][index]
        store.dispatch('removeSku', skuItem)
        for (let key in skuItem) {
          if (key !== 'item_spec') {
            if (key !== 'sku_id') {
              skuItem[key] = ''
            }
          }
          if (!skuItem[key] && key == 'is_old') { // 为了兼容批量填充，以旧换新选否，填充失败
            skuItem[key] = 0
          }
        }
      })
    },
    closePicsDialog() {
      this.picsDialog = false
    },
    picsEnter(index) {
      this.picsCurrent = index
    },
    picsLeave() {
      this.picsCurrent = -1
    },
    removePicsImg: function (index) {
      this.form.pics.splice(index, 1)
      this.picsOldLen = this.form.pics.length
    },
    handleSkuChange(data) {
      this.updateSku()
    },
    updateSku() {
      let arr = []
      let skus = []

      this.skus.forEach((item, i) => {
        if (item.checked_sku.length > 0) {
          arr.push({ ...item, attribute_sort: i + 1 })
        }
      })
      if (arr.length > 0) {
        let n = arr.findIndex(item => JSON.parse(item.is_image))
        if (n != -1) {
          let obj = { ...arr[n] }
          let imgs = []
          let addedImg = this.specImages
          obj.checked_sku.forEach(item => {
            let added = addedImg.find(n => n.spec_value_id === item)
            let img = {
              spec_value_id: item,
              item_spec: this.getSkuName(item, obj.sku_value),
              item_image_url: added ? added.item_image_url : []
            }
            imgs.push(img)
          })
          this.specImages = imgs
          arr[n] = obj
          // arr.splice(n, 1)
          // arr.unshift(obj)
        }
        arr.forEach(item => {
          let skuGroup = []
          if (item.checked_sku.length > 0) {
            item.checked_sku.forEach(checked => {
              let issue = item.sku_value.find(sku => sku.attribute_value_id === checked)
              let obj = {
                spec_id: item.sku_id,
                spec_value_id: issue.attribute_value_id,
                spec_value_name: issue.attribute_value,
                spec_custom_value_name: issue.custom_attribute_value || '',
                attribute_sort: item.attribute_sort
              }
              skuGroup.push(obj)
            })
            skus.push(skuGroup)
          }
        })

        let allSku = this.generateSkus(skus)
        // if (this.skus.length > 1 && !allSku[0].length) {
        //   return false
        // }
        let skuList = []
        allSku.forEach(item => {
          // 根据规格值唯一去接口返回得数据this.spec_items取值 spec_value_id 以id作为区分 保证取消勾选后再次勾选数据还在
          let sameData = null
          if(this.spec_items?.length) {
            const itemSpecArr =  item.length ? item?.map(s => s.spec_value_id) : [item.spec_value_id]
            this.spec_items.forEach((spec) => {
              const detailItemSpecArr = spec.item_spec.map(s => s.spec_value_id)
              // id数组排序 保证勾选顺序改变后 仍然可以判断
              if(JSON.stringify(itemSpecArr.sort()) === JSON.stringify(detailItemSpecArr.sort())) {
                sameData = {...spec}
                sameData.price = sameData.price / 100
                delete sameData.item_spec // 避免污染
                return
              }
            })
          }
          let obj = {
            is_default: false,
            sku_id: this.generateSkuids(item),
            is_use: '1',
            item_spec: item.length ? item : [item],
            approve_status: '',
            store: '',
            item_bn: '',
            weight: '',
            volume: '',
            price: '',
            cost_price: '',
            market_price: '',
            sku_describe: '',
            barcode: '',
            point_num: '',
            is_old: 0,
            ...sameData
          }
          skuList.push(obj)
        })
        let sku_list = []
        if (this.editingSkus.length > 0) {
          this.editingSkus.forEach(item => {
            // let in_item = skuList.find(n => item.sku_id === n.sku_id)
            let in_item = this.skuJoin(skuList, item)
            if (in_item) {
              sku_list.push(item)
            }
            // if (!in_item) {
            //   store.dispatch('removeSku', item)
            // }
          })
          store.dispatch('setSkus', sku_list)
        }

        this.specTotal = skuList.length
        let list = []
        let len = Math.ceil(skuList.length / this.specPagesize)
        for (let i = 0; i < len; i++) {
          let childs = skuList.slice(i * this.specPagesize, i * this.specPagesize + this.specPagesize)
          list.push(childs)
        }
        if (this.editingSkus.length > 0) {

          list.forEach((item, a) => {
            item.forEach((child, b) => {
              // let in_sku = this.editingSkus.find(editor => editor.sku_id === child.sku_id)
              let in_sku = this.skuJoin(this.editingSkus, child)
              if (in_sku) {
                // Object.assign(child, in_sku)
                list[a][b] = { ...child, ...in_sku }
                list[a][b].item_spec = child.item_spec
              }
            })
          })
        }

        list.forEach(item => {
          item.forEach(obj => {
            store.dispatch('setSku', obj)
          })
          // let obj={
          //   item:item,
          //   isList:true
          // }
          //   store.dispatch('setSku',obj)
        })
        this.specItems = list


      } else {
        this.specItems = []
      }

    },
    skuJoin(skuList, item) {
      let obg = null
      skuList.forEach(i => {
        let arr1 = i.sku_id.split('_')
        let arr2 = item.sku_id.split('_')
        let arr3 = []

        arr2.forEach(n => {
          let type = arr1.find(s => s === n)
          if (type) {
            arr3.push(type)
          }
        })
        if (arr3.length === arr1.length) {
          obg = i
        }
      })
      return obg
    },
    getSkuName(id, skus) {
      let sku = skus.find(item => id === item.attribute_value_id)
      if (sku) {
        return sku.attribute_value
      }
    },
    priceformatter: function (row, column) {
      return '￥' + row.price / 100;
    },
    generateSkuids(data) {
      if (data.length) {
        let skuIds = []
        data.forEach(child => {
          skuIds.push(child.spec_value_id)
        })
        return skuIds.join('_')
      } else {
        return data.spec_value_id
      }
    },
    generateSkus(data) {
      let len = data.length
      if (len >= 2) {
        let len1 = data[0].length
        let len2 = data[1].length
        let newlen = len1 * len2
        let temp = new Array(newlen)
        let index = 0
        for (let i = 0; i < len1; i++) {
          for (let j = 0; j < len2; j++) {
            if (Array.isArray(data[0][i])) {
              temp[index] = [...data[0][i], data[1][j]]
            } else {
              temp[index] = [data[0][i], data[1][j]]
            }
            index++
          }
        }
        let newArray = new Array(len - 1)
        for (let i = 2; i < len; i++) {
          newArray[i - 1] = data[i]
        }
        newArray[0] = temp
        return this.generateSkus(newArray)
      }
      else {
        return data[0]
      }
    },
    renderRequire(h, { column }) {
      return h(
        'span', {
        class: 'mark'
      }, '*' + column.label
      )
    },
    handleContent(data) {
      this.content = data
    },
    deleteVideo() {
      this.itemVideo = {}
      this.form.videos = ''
      this.form.videos_url = ''
    },
    // 获取品牌列表
    getBrandList(searchVal = '', isInit = false) {
      const list = []
      getGoodsAttr({
        page: 1,
        pageSize: 1000,
        attribute_type: 'brand',
        attribute_name: searchVal,
        attribute_ids: isInit ? this.form.brand_id : ''
      }).then(res => {
        for (let item of res.data.data.list) {
          list.push({ attribute_name: item.attribute_name, attribute_id: item.attribute_id })
        }
        this.brandList = list
      })
    },
    init() {
      this.getBrandList('', true)
      getShippingTemplatesList(this.templatesListParams).then(response => {
        if (response.data.data.list.length > 0) {
          for (var i in response.data.data.list) {
            this.templatesList.push({
              'template_id': response.data.data.list[i].template_id,
              'name': response.data.data.list[i].name
            })
          }
        } else {
          this.$message({
            type: 'error',
            message: '请先添加运费模板'
          })
          this.$router.push({ path: this.matchInternalRoute('shippingtemplates') })
        }
      })

      getCategory({ is_show: false }).then(response => {
        this.categoryList = response.data.data
      })
      getPointRule().then(response => {
        this.form.point_access = response.data.data.access
      })
    },
    profitStatusChange(val) {
      if (val) {
        this.form.is_profit = true
      } else {
        this.form.is_profit = false
      }
    }, packageItemsChange(val) {
      if (val) {
        this.form.is_package_items = true
      } else {
        this.form.is_package_items = false
      }
    },
    giftStatusChange(val) {
      if (val) {
        this.isGift = true
        // this.form.price = 0
        // this.form.market_price = 0
      } else {
        this.isGift = false
      }
    },
    appointmentStatusChange(val) {
      if (val) {
        this.form.is_appointment = true
      } else {
        this.form.is_appointment = false
      }
    },
    eduStatusChange(val) {
      if (val) {
        if (this.form.is_ec === true) {
          this.form.is_edu = false
          this.$message({ message: '企业购商品不允许参与教育优惠！', type: 'error' })
          return
        }
        this.form.is_edu = true
        this.form.recommend_items = this.form.ac_items = this.form.ac_items_sku = []
        this.showAC = false
      } else {
        this.form.is_edu = false
        this.showAC = true
      }
    },
    teirStatusChange(val) {
      if (val !== 'normal') {
        this.form.recommend_items = this.form.ac_items = this.form.ac_items_sku = []
        this.showAC = false
      } else {
        this.showAC = true
      }
    },

    zitiStatusChange(val) {
      if (val) {
        this.form.is_ziti = true
        this.form.is_addcart_goods = false
      } else {
        this.form.is_ziti = false
      }
    },
    autoIsGoodsChange(val) {
      let content = ''
      if (val && this.is_delivery_goods == 1) {
        content = '该商品当前仅支持普通快递配送，继续则删除该商品的快递配送设置'
      } else if (!val && this.as_recommend.length > 0) {
        content = '该商品目前已被设置为可加购商品，继续则删除所有商品和该商品的加购关联'
      }
      if (content) {
        this.$confirm(content, {
          confirmButtonText: '继续',
          cancelButtonText: '取消'
        }).then(() => {
          this.form.is_addcart_goods = val
          if (val) {
            this.form.is_ziti = false
          }
        }).catch(() => {
          this.form.is_addcart_goods = !val
        })
      } else {
        this.form.is_addcart_goods = val
        if (val) {
          this.form.is_ziti = false
        }
      }
    },

    wecomShareStatusChange(val) {
      if (val) {
        if (this.form.is_ec === true) {
          this.form.wecom_share = false
          this.$message({ message: '企业购商品暂不支持分享！', type: 'error' })
          return
        }
        this.form.wecom_share = true
      } else {
        this.form.wecom_share = false
      }
    },
    ecBuyChange(val) {
      if (val) {
        if (this.form.is_edu === true) {
          this.form.is_ec = false
          this.$message({ message: '教育优惠商品不允许参与企业购！', type: 'error' })
          return
        }
        if (this.form.wecom_share === true) {
          this.$message({ message: '企业购商品暂不支持分享,已自动关闭', type: 'info' })
        }
        this.form.wecom_share = false
        this.form.is_ec = true
      } else {
        this.form.is_ec = false
      }
    },
    autoReceivingChange(val) {
      this.form.auto_receiving = !val
      let message = '此操作将关闭商品支持自动接单功能，是否继续？'
      if (val) {
        message = '此操作将开启商品支持自动接单功能，是否继续？'
      }
      this.$confirm(message, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.form.auto_receiving = val
      })
    },

    oldStatusChange(val) {
      if (val) {
        this.form.is_old = true
      } else {
        this.form.is_old = false
      }
    },

    acStatusChange(val) {
      if (val) {
        this.form.is_acplus = true
      } else {
        this.form.is_acplus = false
      }
    },
    // select值变化
    paramsChange(e) {
      const params = this.params
      for (let i = 0; i < params.length; i++) {
        if (params[i].children && params[i].children.length > 0) {
          const children = params[i].children
          const isHave = children.findIndex(item => item.value == e)
          if (isHave !== -1) {
            this.form.item_params[i].attribute_value_name = children[isHave].label
            break
          }
        }
      }
    },
    onStart(evt) {
      evt.preventDefault()
      this.updateSku()
    },
    beforeRouteLeave(to, from, next) {
      if (!this.isLeave) {
        this.$confirm('确定要离开当前页面，您将丢失已编辑的数据？！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then((res) => {
          store.dispatch('clearSkus')
          next()
        }).catch(() => {
          next(false)
        })
      } else {
        store.dispatch('clearSkus')
        next()
      }
    },
  },
    mounted() {
      store.dispatch('clearSkus')
      if (this.$route.path.split('/')[2] === 'godsphysicalkj') {

        this.form.type = 1
      } else {

        this.form.type = 0
      }

      const _self = this
      async function onload() {
        if (_self.$route.query.is_new) {
          _self.is_new = _self.$route.query.is_new
        }
        if (_self.$route.params.itemId) {
          _self.isEditor = true
          _self.mainCateLoader = true
          // 初始化门店数据
          await getItemsDetail(_self.$route.params.itemId).then(response => {
            let itemsDetailData = response.data.data
            if (itemsDetailData.regions_id) {
              _self.select_regions_value = itemsDetailData.regions_id
            }
            _self.form.pics = itemsDetailData.pics
            _self.showAC = (itemsDetailData.special_value === 'normal') ? true : false
            // 处理图片列表
            var picList = []
            for (var item in itemsDetailData.pics) {
              var newpic = {}
              newpic.url = _self.wximageurl + itemsDetailData.pics[item]
              picList.push(newpic)
            }
            // TDK处理
            if (itemsDetailData && itemsDetailData.tdk_content) {
              _self.tdk_info = JSON.parse(itemsDetailData.tdk_content)
            }
            _self.picsList = picList
            _self.is_delivery_goods = itemsDetailData.is_delivery_goods
            _self.as_recommend = itemsDetailData.as_recommend || []
            let obj = _self.form
            _self.form = ({ obj } = {
              item_id: _self.is_new ? '' : itemsDetailData.item_id,
              item_source: itemsDetailData.item_source,
              item_type: itemsDetailData.item_type,
              special_type: itemsDetailData.special_type,
              item_category: itemsDetailData.item_category.length > 0 ? itemsDetailData.item_category : [],
              item_name: itemsDetailData.item_name,
              sort: itemsDetailData.sort,
              tax_rate: itemsDetailData.tax_rate,
              item_bn: _self.is_new ? '' : itemsDetailData.item_bn,
              brief: itemsDetailData.brief,
              item_en_name: itemsDetailData.item_en_name,
              item_tag_name: itemsDetailData.item_tag_name,
              weight: itemsDetailData.weight,
              volume: itemsDetailData.volume,
              price: itemsDetailData.price / 100,
              market_price: itemsDetailData.market_price / 100,
              sku_describe: itemsDetailData.sku_describe,
              cost_price: itemsDetailData.cost_price / 100,
              barcode: itemsDetailData.barcode,
              item_unit: itemsDetailData.item_unit,
              rebate: itemsDetailData.rebate / 100,
              store: itemsDetailData.store,
              shustore: itemsDetailData.shustore,
              brand_id: itemsDetailData.brand_id,
              templates_id: itemsDetailData.templates_id ? itemsDetailData.templates_id.toString() : '',
              approve_status: itemsDetailData.approve_status,
              pics: itemsDetailData.pics,
              videos: itemsDetailData.videos,
              videos_url: itemsDetailData.videos_url,
              nospec: itemsDetailData.nospec,
              is_show_specimg: itemsDetailData.is_show_specimg,
              item_params: itemsDetailData.item_params,
              item_main_cat_id: itemsDetailData.item_main_cat_id,
              is_gift: itemsDetailData.is_gift,
              crossborder_tax_rate: itemsDetailData.crossborder_tax_rate,
              origincountry_id: itemsDetailData.origincountry_id,
              taxstrategy_id: itemsDetailData.taxstrategy_id,
              taxation_num: itemsDetailData.taxation_num,
              type: itemsDetailData.type,
              is_profit: itemsDetailData.is_profit,
              point_num: itemsDetailData.point_num,
              is_package_items: itemsDetailData.is_package_items,
              tdk_content: itemsDetailData.tdk_content,
              is_appointment: itemsDetailData.is_appointment,
              appointment_time: itemsDetailData.appointment_time,
              appointment_start: itemsDetailData.appointment_start,
              appointment_end: itemsDetailData.appointment_end,
              recommend_items: itemsDetailData.recommend_items,
              ac_items: itemsDetailData.ac_items,
              ac_items_sku: itemsDetailData.ac_items_sku,
              home_pic: itemsDetailData.home_pic,
              video_pic_url: itemsDetailData.video_pic_url,
              video_auto_play: itemsDetailData.video_auto_play === 0 ? false : true,
              is_edu: itemsDetailData.is_edu == 1,
              is_old: itemsDetailData.is_old == 1,
              is_ec: itemsDetailData.is_ec == 1,
              auto_receiving: itemsDetailData.auto_receiving == 1,
              is_ziti: itemsDetailData.is_ziti == 1,
              is_addcart_goods: itemsDetailData.is_addcart_goods == 1,
              wecom_share: itemsDetailData.wecom_share == 1,
              origin_bn: itemsDetailData.origin_bn,
              default_select_item_bn: itemsDetailData.default_select_item_bn,
              default_spu_item_bn: itemsDetailData.default_spu_item_bn,
              special_value: itemsDetailData.special_value,
              is_main_product: itemsDetailData.is_main_product,
            })

            getPointRule().then(response => {
              _self.form.point_access = response.data.data.access
            })
            _self.picsOldLen = _self.form.pics.length
            if (!itemsDetailData.item_main_cat_id) {
              _self.fetchMainCate()
            } else {
              let category = itemsDetailData.item_category_main
              _self.categoryNames = [category[0].category_name, category[0].children[0].category_name, category[0].children[0].children[0].category_name]
              _self.generateParams(itemsDetailData.item_params_list)
            }
            if (!_self.form.nospec) {
              _self.generateSpec(itemsDetailData.item_spec_list)
              _self.specImages = itemsDetailData.spec_images
              _self.spec_items = itemsDetailData.spec_items
              itemsDetailData.spec_items.forEach(item => {
                item.item_spec.forEach(child => {
                  let checkedIndex = _self.skus.findIndex(n => child.spec_id === n.sku_id)
                  let isin = _self.skus[checkedIndex].checked_sku.findIndex(k => child.spec_value_id === k)
                  if (isin === -1) {
                    _self.skus[checkedIndex].checked_sku.push(child.spec_value_id)
                  }
                })
              })

              itemsDetailData.spec_items.forEach(item => {
                let sku = Object.assign({}, item)
                sku.market_price = item.market_price / 100
                sku.sku_describe = item.sku_describe
                sku.cost_price = item.cost_price / 100
                sku.price = item.price / 100
                sku.item_bn = _self.is_new ? '' : item.item_bn
                let itemId = []
                let specs = []
                item.item_spec.forEach((sub, idx) => {
                  specs.push({
                    spec_id: sub.spec_id,
                    spec_value_id: sub.spec_value_id,
                    spec_value_name: sub.spec_value_name,
                    spec_custom_value_name: sub.spec_custom_value_name || '',
                    attribute_sort: idx + 1
                  })
                  itemId.push(sub.spec_value_id)
                })
                sku.item_spec = specs
                itemId = itemId.join('_')
                Object.assign(sku, { sku_id: itemId })
                store.dispatch('setSku', sku)
              })
              _self.updateSku()
            }
            if (typeof itemsDetailData.intro === 'object') {
              _self.mode = 'component'
              _self.content = itemsDetailData.intro
            } else {
              _self.form.intro = itemsDetailData.intro
            }
          })
          // .catch(error => {
          //   _self.$router.go(-1)
          // })
          _self.relItemsIds = _self.form.recommend_items
          _self.acItemsIds = _self.form.ac_items
          _self.loader = false
          _self.itemVideo = { media_id: _self.form.videos, url: _self.form.videos_url }
        } else {
          _self.fetchMainCate()
          _self.loader = false
        }
        await _self.init()
      }
      onload()
      this.getOrigincountry()
    }
  }
</script>
<style lang="scss">
.vue-treeselect__placeholder {
  line-height: 40px;
}
</style>
<style scoped lang="scss">
.fallback-class {
  width: 118px;
  height: 118px;
}

.chosen-class {
  position: relative;
  height: 118px;
  text-align: center;

  .svg-icon {
    width: 26px;
    height: 26px;
  }

  &::after {
    position: absolute;
    top: 0;
    left: 118px;
    right: 118px;
    bottom: 0;
    background: #ffb28b;
    box-shadow: inset 0 0 0 2px #ff5000;
    content: "";
  }
}

.sku-value {
  margin-right: 10px;
}

.sku-img {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  vertical-align: middle;
}

.sku-img-pick {
  color: #999;
  cursor: pointer;
}

.avatar-uploader-icon {
  font-size: 48px;
}

.sku-select__item {
  display: flex;
  position: relative;
}

.sku-select__checkgroup {
  padding-left: 20px;
  flex: 1;
}

.sku-select__checkitem {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 20px;
}

.sku-remove__icon {
  position: absolute;
  width: 30px;
  height: 30px;
  top: -20px;
  right: -20px;
  background: #ff5000;
  line-height: 30px;
  text-align: center;
  color: #fff;
  font-size: 15px;
  cursor: pointer;
}

.sku-tip {
  padding-left: 10px;
  font-size: 12px;
  color: #999;
}

.pics-box {
  overflow: hidden;

  .goodspic-wrap {
    float: left;
    margin-right: 5px;
    overflow: hidden;

    .goodspic {
      position: relative;
      float: left;
      width: 120px;
      height: 120px;
      margin: 0 5px 10px;
      border: 1px solid #ddd;
      border-radius: 8px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }

      .goodspic-mask {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .2);
        cursor: pointer;

        &.on {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .iconfont {
          margin: 0 8px;
          font-size: 20px;
          color: #fff;
        }
      }
    }
  }

  .upload-box {
    display: inline-block;
    width: 120px;
    height: 120px;
    line-height: 120px;
    text-align: center;

    .iconfont {
      font-size: 30px;
      color: #ccc;
    }
  }
}

.tpl_item {
  display: inline-block;
  border: 1px solid #ddd;
  background-color: #fff;
  text-align: left;
  padding: 0 15px;
  line-height: 36px;
  cursor: pointer;
  border-radius: 3px;

  .iconfont {
    margin-right: 5px;
  }
}

.tpl_item .fa {
  width: 12px;
  height: 22px;
  vertical-align: middle;
  display: inline-block;
  margin-right: 1em;
  font-size: 20px;
  text-align: center;
}

.vue-html5-editor {
  vertical-align: top;
  display: inline-block;
}

.demo-ruleForm {
  .vue-html5-editor {
    width: 80%;
  }
}
</style>
